import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@lla-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { ATTENDANCE_API_URL } from './attendance-api-routes'
import { IAttendanceResponse, IEmplyeeAttendanceDetailReponse } from '../interfaces/attendance.interface'

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  constructor(private http: HttpService) {}

  getAttendance(weekNumber: number): Observable<IAttendanceResponse> {
    return this.http.post(ATTENDANCE_API_URL.getAttendance, { weekNumber })
  }

  exportAttendance(weekNumber: number): Observable<IExportFileResponse> {
    return this.http.post(ATTENDANCE_API_URL.exportAttendance, { weekNumber })
  }

  resyncAttendance(weekNumber: number): Observable<IAttendanceResponse> {
    return this.http.post(ATTENDANCE_API_URL.resyncAttendance, { weekNumber })
  }

  employeeAttendanceDetails(
    employeeId: string,
    locationId: string,
    from: Date
  ): Observable<IEmplyeeAttendanceDetailReponse> {
    return this.http.post(ATTENDANCE_API_URL.employeeAttendanceDetails(employeeId, locationId), { from })
  }
}
