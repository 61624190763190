import { ApplicationRoutes } from '../../app-routes-names'

export const AdminRoutes = {
  shopTires: 'location-tires',
  userList: 'user-list',
  userDetails: 'user-details/:userId',
  addUser: 'add-user',
  setMargins: 'set-margins',
  surveyList: 'survey-list'
}

const { admin } = ApplicationRoutes

export const AdminPaths = {
  shopTires: `/${admin}/${AdminRoutes.shopTires}`,
  userList: `/${admin}/${AdminRoutes.userList}`,
  userDetails: (userId: string) => `/${admin}/${AdminRoutes.userDetails.replace(':userId', userId)}`,
  addUser: `/${admin}/${AdminRoutes.addUser}`,
  setMargins: `/${admin}/${AdminRoutes.setMargins}`,
  surveyList: `/${admin}/${AdminRoutes.surveyList}`
}
