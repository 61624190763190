import { environment } from '@lla-platform/core/core-util'

export const PROMOTIOMS_API_URL = {
  baseAdmin: (promotionId?: string) =>
    `${environment.apiUrl}/managepromotions/promotions${promotionId ? '/' + promotionId : ''}`,
  getAllPromotions: `${environment.apiUrl}/promotions`,
  getPromotionsChannels: `${environment.apiUrl}/managepromotions/promotions/channels`,
  promotionsAttachments: (promotionId: string, fileId?: string) =>
    `${environment.apiUrl}/managepromotions/promotions/files/${promotionId}${fileId ? '/' + fileId : ''}`,
  getPromotionUsers: `${environment.apiUrl}/managepromotions/promotions/locations/users`,
  sendPromotionEmail: `${environment.apiUrl}/managepromotions/promotions/email`
}
