import { Injectable } from '@angular/core'
import { IShopInfo } from '@lla-platform/core/core-data-access'
import { isEmpty, isObject } from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  convertEmptyToNull(payload: any) {
    const objectKeys = Object.keys(payload)
    for (const key of objectKeys) {
      const value = payload[key]
      if (isObject(value) && isEmpty(value)) {
        payload[key] = null
      }
    }
    return payload
  }

  generateRandomColor() {
    const letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  generateRandomString() {
    return (Math.random() + 1).toString(36).substring(2)
  }

  blobToBase64(file: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result as string)
      reader.readAsDataURL(file)
    })
  }

  filterData<T extends IShopInfo>(data: T[], region?: string | 'all', division?: string | 'all'): T[] {
    let result: T[] = [...data]
    if (region) {
      result =
        region !== '' && region !== 'all'
          ? result.filter((el) => el.locationInfo?.region === region)
          : [...result]
    }

    if (division) {
      result =
        division !== '' && division !== 'all'
          ? result.filter((el) => el.locationInfo?.division === division)
          : [...result]
    }

    return result
  }

  formateMins(value: number): string {
    const minutes = value % 60
    const hours = (value - minutes) / 60
    return (
      (hours < 10 ? '0' : '') +
      hours.toFixed() +
      ':' +
      (minutes < 10 ? '0' : '') +
      minutes.toFixed() +
      'h'
    )
  }
}
