import { Component, Input } from '@angular/core'

@Component({
  selector: 'lla-no-report-banner',
  templateUrl: './no-report-banner.component.html',
  styleUrls: ['./no-report-banner.component.scss']
})
export class NoReportBannerComponent {
  @Input() containerClass = ''
  @Input() info: { title?: string; subTitle?: string; imagePath: string }
}
