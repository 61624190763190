import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() showNumberOfRows = true
  @Input() numberOfRows = 0
  @Input() isLoading = false
  @Input() showDownloadButton = true
  @Output() exportBtnClicked = new EventEmitter()
}
