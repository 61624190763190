import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'lla-collapsable-title',
  templateUrl: './collapsable-title.component.html',
  styleUrls: ['./collapsable-title.component.scss']
})
export class CollapsableTitleComponent {
  @Input() expandable = true
  @Input() expanded = false
  @Input() label?: string
  @Input() icon = 'garage'
  @Input() fullClickable = false

  @Output() toggleCollapse = new EventEmitter()
}
