import { ApplicationRoutes } from '../../../app-routes-names'

export const PromotionsRoutes = {
  list: 'list',
  promotionsList: 'manage/list',
  editPromotion: 'manage/details/:promotionId',
  addPromotion: 'manage/details',
  emailPromotions: 'manage/email'
}

const { promotions } = ApplicationRoutes

export const PromotionsPaths = {
  list: `/${promotions}/${PromotionsRoutes.list}`,
  promotionsList: `/${promotions}/${PromotionsRoutes.promotionsList}`,
  editPromotion: (promotionId: string) =>
    `/${promotions}/${PromotionsRoutes.editPromotion.replace(':promotionId', promotionId)}`,
  addPromotion: `/${promotions}/${PromotionsRoutes.addPromotion}`,
  emailPromotions: `/${promotions}/${PromotionsRoutes.emailPromotions}`
}
