<div
  class="bg-white border-t border-gray-100 w-full pr-2xl pl-lg md:pr-[28px] py-[6px] flex items-center justify-between"
>
  <div class="flex-1">
    <ng-content></ng-content>
  </div>
  <div class="flex items-center">
    <div *ngIf="showNumberOfRows" class="pr-xl lg:pr-2xl text-txt-secondary text-sm leading-sm">
      Showing {{ numberOfRows }} rows
    </div>
    <ng-container *ngIf="showDownloadButton">
      <div
        class="flex items-center cursor-pointer"
        (click)="!isLoading ? exportBtnClicked.emit() : undefined"
      >
        <div class="hidden lg:block mr-sm text-primary text-sm leading-sm">Export to CSV</div>
        <div class="flex text-primary">
          <svg-icon *ngIf="!isLoading" class="!text-sm" key="download-cloud"></svg-icon>
        </div>
        <lla-loading-spinner *ngIf="isLoading"></lla-loading-spinner>
      </div>
    </ng-container>
  </div>
</div>
