import { FilterStateKeys } from './filters.model'

export interface IPageQueryParamsMap {
  [key: string]: FilterStateKeys[]
}

export const AppVersion = '1'
export const NewVersionIndicator = 'new-version'

export const PageQueryParamsMap: IPageQueryParamsMap = {
  '/work-orders/summary': ['workOrdersSummaryFilter', 'workOrdersSummarySortState'],
  '/work-orders/list': [
    'workOrdersListFilters',
    'workOrdersListColumnsVisibility',
    'workOrdersListAdvancedMode'
  ],
  '/receivable/receivables-list': [
    'receivableListFilters',
    'receivableColumnsVisibility',
    'receivableListAdvancedMode',
    'receivableListReturnUrl'
  ],
  '/receivable/locations': ['shopReceivableSummaryFilter'],
  '/receivable/ar': ['arSummaryFilter'],
  '/receivable/ar-payments': ['arPaymentsSummaryFilter'],
  '/receivable/accounts': ['accountReceivableFilter'],
  '/location-invoices/invoices-list': [
    'invoicesListFilters',
    'invoicesListColumnsVisibility',
    'invoicesListAdvancedMode',
    'invoicesListReturnUrl'
  ],
  '/location-invoices/gross-invoices-list': [
    'grossListFilters',
    'grossListColumnsVisibility',
    'grossListAdvancedMode',
    'grossListReturnUrl'
  ],
  '/location-invoices/package-gp-list': [
    'gpPackageListFilters',
    'gpPackageListColumnsVisibility',
    'gpPackageListAdvancedMode',
    'gpPackageListReturnUrl'
  ],
  '/location-invoices/invoices-customer-type': ['customersSummaryFilter'],
  '/location-invoices/invoices-deferred-type': ['deferredTypesSummaryFilter', 'deferredTypesSortState'],
  '/location-invoices/invoices-gross-profit': ['grossProfitSummaryFilter', 'grossProfitSortState'],
  '/location-invoices/package-gp-location': [
    'gpPackageByLocationSummaryFilter',
    'gpPackageByLocationSortState'
  ],
  '/location-invoices/package-gp-category': [
    'gpPackageByCategorySummaryFilter',
    'gpPackageByCategorySortState'
  ],
  '/location-invoices/performance-package-gp': ['packageUnperformedSummaryFilter'],
  '/contacts/list': ['customersListFilters', 'customersListColumnsVisibility'],
  '/communications/list': ['communicationsListTableFilters', 'communicationsListColumnsVisibility'],
  '/targets/objectives': [
    'weeklyTargetSelectedWeekNumber',
    'weeklyTargetExpandedShopsId',
    'weeklyTargetSelectedRegion',
    'weeklyTargetSelectedDivision'
  ],
  '/targets/objectives-vs-comp': [
    'weeklyTargetSelectedWeekNumber',
    'weeklyTargetExpandedShopsId',
    'weeklyTargetSelectedRegion',
    'weeklyTargetSelectedDivision'
  ],
  '/targets/locations': [
    'weeklyTargetSelectedWeekNumber',
    'TargetRankingByLocationSelectedRegion',
    'TargetRankingByLocationSelectedDivision',
    'weeklyTargetRankingSortState'
  ],
  '/targets/regions': [
    'weeklyTargetSelectedWeekNumber',
    'TargetRankingByRegionSelectedRegion',
    'TargetRankingByRegionSelectedDivision',
    'weeklyTargetRankingSortState'
  ],
  '/reports/calls': ['callsReportFilter'],
  '/payments/payments-list': [
    'paymentsListFilters',
    'paymentsListColumnsVisibility',
    'paymentsListAdvancedMode'
  ],
  '/admin/location-tires': ['adminShopTiresExpandedShopsId'],
  '/promotions/list': ['promotionsExpandedShopsId'],
  '/tires/summary-program': ['tiresSummaryFilter'],
  '/tires/summary-levels': ['tiresByLevelSummaryFilter'],
  '/tires/summary-stockable': ['tiresByStockableSummaryFilter'],
  '/tires/list': [
    'tiresListFilters',
    'tiresListColumnsVisibility',
    'tiresListAdvancedMode',
    'tiresListReturnUrl'
  ],
  '/tires/list-full': [
    'tiresListFilters',
    'tiresListColumnsVisibility',
    'tiresListAdvancedMode',
    'tiresListReturnUrl'
  ],
  '/admin/user-list': ['adminUserListSortState'],
  '/promotions/manage/list': ['adminPromotionsListSortState'],
  '/tracker/summary': ['trackerLocationId'],
  '/tires/lost-tire': ['locationId'],
  '/contacts/communication-type': ['communicationTypeFilter'],
  '/location-invoices/weekly-car-count': ['weeklyCarCountsFilter'],
  '/tires/lost-tire-list': [
    'lostTiresListFilters',
    'lostTiresListColumnsVisibility',
    'lostTiresListAdvancedMode'
  ],
  '/promotions/manage/email': ['promotionsUsersExpandedIds'],
  '/stockable/items': ['locationId'],
  '/attendance': [
    'attendanceSelectedWeekNumber',
    'attendanceExpandedShopsId',
    'attendanceSelectedRegion',
    'attendanceSelectedDivision'
  ]
}

export const PersistFilters: FilterStateKeys[] = [
  'showLargeMenu',
  'showAppliedTableFilters',
  'weeklyTargetExpandedShopsId',
  'attendanceExpandedShopsId',
  'adminShopTiresExpandedShopsId',
  'promotionsExpandedShopsId',
  'promotionsUsersExpandedIds'
]
