export interface IBaseShop {
  id: string
  name?: string
  nickname?: string
  noOfBays?: number
  state?: string
  region?: string
  division?: string
  groups?: string[]
  userHasAccess?: boolean
}

export interface IShopInfo {
  location: IBaseShop
  locationInfo?: IBaseShop
}
