import { Location } from '@angular/common'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store'
import { isEmpty, isObject, omit, pick, pickBy } from 'lodash'
import {
  SetFilters,
  SetFiltersFromUrlQueryParams,
  SetAdvancedTableFilters,
  SetUrlQueryParamsFromState
} from './filters.actions'
import { FiltersStateModel, FilterStateKeys } from './filters.model'
import { AppVersion, PageQueryParamsMap, PersistFilters, NewVersionIndicator } from './filters.constant'
import jwt_decode from 'jwt-decode'
import sign from 'jwt-encode'
import { FullReceivableType, InvoicePackageGroup } from '@lla-platform/receivable/receivable-data-access'
import { PeriodType, PeriodTypeButtonsInfo } from '../../enums/period-type.enum'
import { LOCAL_STORAGE_KEYS, environment } from '@lla-platform/core/core-util'
import { ITableFilter } from '../../interfaces/advanced-table.interface'

@State<FiltersStateModel>({
  name: 'filters'
})
@Injectable()
export class FiltersState implements NgxsOnInit {
  constructor(
    private location: Location,
    private router: Router
  ) {}

  @Selector()
  static isLoading(state: FiltersStateModel) {
    return state.isLoading
  }

  @Selector()
  static receivableListFilters(state: FiltersStateModel) {
    return state.receivableListFilters ?? { page: 1 }
  }

  @Selector()
  static receivableColumnsVisibility(state: FiltersStateModel) {
    return state.receivableColumnsVisibility
  }

  @Selector()
  static receivableListAdvancedMode(state: FiltersStateModel) {
    return state.receivableListAdvancedMode
  }

  @Selector()
  static shopReceivableSummaryFilter(state: FiltersStateModel) {
    return (
      state.shopReceivableSummaryFilter ?? {
        locationIds: [],
        receivablesType: FullReceivableType.All
      }
    )
  }

  @Selector()
  static accountReceivableFilter(state: FiltersStateModel) {
    return (
      state.accountReceivableFilter ?? {
        locationIds: [],
        receivablesType: FullReceivableType.All
      }
    )
  }

  @Selector()
  static arSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return (
      state.arSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static callsReportFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.YearToDate)?.extraInfo
    return (
      state.callsReportFilter ?? {
        periodType: PeriodType.YearToDate,
        ...dateInfo
      }
    )
  }

  @Selector()
  static invoicesListFilters(state: FiltersStateModel) {
    return state.invoicesListFilters ?? { page: 1 }
  }

  @Selector()
  static invoicesListColumnsVisibility(state: FiltersStateModel) {
    return state.invoicesListColumnsVisibility
  }

  @Selector()
  static invoicesListAdvancedMode(state: FiltersStateModel) {
    return state.invoicesListAdvancedMode
  }

  @Selector()
  static invoicesListReturnUrl(state: FiltersStateModel) {
    return state.invoicesListReturnUrl
  }

  @Selector()
  static grossListFilters(state: FiltersStateModel) {
    return state.grossListFilters ?? { page: 1 }
  }

  @Selector()
  static grossListColumnsVisibility(state: FiltersStateModel) {
    return state.grossListColumnsVisibility
  }

  @Selector()
  static grossListAdvancedMode(state: FiltersStateModel) {
    return state.grossListAdvancedMode
  }

  @Selector()
  static grossListReturnUrl(state: FiltersStateModel) {
    return state.grossListReturnUrl
  }

  @Selector()
  static gpPackageListFilters(state: FiltersStateModel) {
    return state.gpPackageListFilters ?? { page: 1 }
  }

  @Selector()
  static gpPackageListColumnsVisibility(state: FiltersStateModel) {
    return state.gpPackageListColumnsVisibility
  }

  @Selector()
  static gpPackageListAdvancedMode(state: FiltersStateModel) {
    return state.gpPackageListAdvancedMode
  }

  @Selector()
  static gpPackageListReturnUrl(state: FiltersStateModel) {
    return state.gpPackageListReturnUrl
  }

  @Selector()
  static receivableListReturnUrl(state: FiltersStateModel) {
    return state.receivableListReturnUrl
  }

  @Selector()
  static showLargeMenu(state: FiltersStateModel) {
    return this.evaluateBoolean(state.showLargeMenu)
  }

  @Selector()
  static showAppliedTableFilters(state: FiltersStateModel) {
    return this.evaluateBoolean(state.showAppliedTableFilters)
  }

  @Selector()
  static urlFilterParam() {
    const currentUrl = new URL(document.URL)
    return Object.fromEntries(new URLSearchParams(currentUrl.search) as any)['filters']
  }

  @Selector()
  static customersSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return (
      state.customersSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static deferredTypesSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return (
      state.deferredTypesSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static deferredTypesSortState(state: FiltersStateModel) {
    return state.deferredTypesSortState
  }

  @Selector()
  static grossProfitSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return (
      state.grossProfitSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static grossProfitSortState(state: FiltersStateModel) {
    return state.grossProfitSortState
  }

  @Selector()
  static gpPackageByLocationSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return {
      ...(state.gpPackageByLocationSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        ...dateInfo
      }),
      invoicePackageGroupBy: InvoicePackageGroup.Location
    }
  }

  @Selector()
  static gpPackageByLocationSortState(state: FiltersStateModel) {
    return state.gpPackageByLocationSortState
  }

  @Selector()
  static gpPackageByCategorySummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return {
      ...(state.gpPackageByCategorySummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }),
      invoicePackageGroupBy: InvoicePackageGroup.Category
    }
  }

  @Selector()
  static gpPackageByCategorySortState(state: FiltersStateModel) {
    return state.gpPackageByCategorySortState
  }

  @Selector()
  static customersListColumnsVisibility(state: FiltersStateModel) {
    return state.customersListColumnsVisibility
  }

  @Selector()
  static customersListFilters(state: FiltersStateModel) {
    return state.customersListFilters
  }

  @Selector()
  static listPagesPath(state: FiltersStateModel) {
    return state.listPagesPath
  }

  @Selector()
  static clientDetailReturnUrl(state: FiltersStateModel) {
    return state.clientDetailReturnUrl
  }

  @Selector()
  static communicationsListColumnsVisibility(state: FiltersStateModel) {
    return state.communicationsListColumnsVisibility
  }

  @Selector()
  static communicationsListTableFilters(state: FiltersStateModel) {
    return state.communicationsListTableFilters
  }

  @Selector()
  static weeklyTargetExpandedShopsId(state: FiltersStateModel) {
    return state.weeklyTargetExpandedShopsId ?? []
  }

  @Selector()
  static weeklyTargetSelectedWeekNumber(state: FiltersStateModel) {
    return state.weeklyTargetSelectedWeekNumber
  }

  @Selector()
  static weeklyTargetSelectedRegion(state: FiltersStateModel) {
    return state.weeklyTargetSelectedRegion
  }

  @Selector()
  static weeklyTargetSelectedDivision(state: FiltersStateModel) {
    return state.weeklyTargetSelectedDivision
  }

  @Selector()
  static TargetRankingByLocationSelectedRegion(state: FiltersStateModel) {
    return state.TargetRankingByLocationSelectedRegion
  }

  @Selector()
  static TargetRankingByLocationSelectedDivision(state: FiltersStateModel) {
    return state.TargetRankingByLocationSelectedDivision
  }

  @Selector()
  static TargetRankingByRegionSelectedRegion(state: FiltersStateModel) {
    return state.TargetRankingByRegionSelectedRegion
  }

  @Selector()
  static TargetRankingByRegionSelectedDivision(state: FiltersStateModel) {
    return state.TargetRankingByRegionSelectedDivision
  }

  @Selector()
  static arPaymentsSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find(
      (el) => el.value === PeriodType.PreviousWeek
    )?.extraInfo
    return (
      state.arPaymentsSummaryFilter ?? {
        periodType: PeriodType.PreviousWeek,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static packageUnperformedSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.Yesterday)?.extraInfo
    return (
      state.packageUnperformedSummaryFilter ?? {
        periodType: PeriodType.Yesterday,
        ...dateInfo
      }
    )
  }

  @Selector()
  static paymentsListFilters(state: FiltersStateModel) {
    return state.paymentsListFilters ?? { page: 1 }
  }

  @Selector()
  static paymentsListColumnsVisibility(state: FiltersStateModel) {
    return state.paymentsListColumnsVisibility
  }

  @Selector()
  static paymentsListAdvancedMode(state: FiltersStateModel) {
    return state.paymentsListAdvancedMode
  }

  @Selector()
  static adminShopTiresExpandedShopsId(state: FiltersStateModel) {
    return state.adminShopTiresExpandedShopsId ?? []
  }

  @Selector()
  static promotionsExpandedShopsId(state: FiltersStateModel) {
    return state.promotionsExpandedShopsId ?? []
  }

  @Selector()
  static promotionsUsersExpandedIds(state: FiltersStateModel) {
    return state.promotionsUsersExpandedIds ?? []
  }

  @Selector()
  static tiresSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.Yesterday)?.extraInfo
    return (
      state.tiresSummaryFilter ?? {
        periodType: PeriodType.Yesterday,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static tiresByLevelSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.Yesterday)?.extraInfo
    return (
      state.tiresByLevelSummaryFilter ?? {
        periodType: PeriodType.Yesterday,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static tiresByStockableSummaryFilter(state: FiltersStateModel) {
    const dateInfo = PeriodTypeButtonsInfo().find((el) => el.value === PeriodType.Yesterday)?.extraInfo
    return (
      state.tiresByStockableSummaryFilter ?? {
        periodType: PeriodType.Yesterday,
        locationIds: [],
        ...dateInfo
      }
    )
  }

  @Selector()
  static tiresListFilters(state: FiltersStateModel) {
    return state.tiresListFilters ?? { page: 1 }
  }

  @Selector()
  static tiresListColumnsVisibility(state: FiltersStateModel) {
    return state.tiresListColumnsVisibility
  }

  @Selector()
  static tiresListAdvancedMode(state: FiltersStateModel) {
    return state.tiresListAdvancedMode
  }

  @Selector()
  static tiresListReturnUrl(state: FiltersStateModel) {
    return state.tiresListReturnUrl
  }

  @Selector()
  static lostTiresListFilters(state: FiltersStateModel) {
    return state.lostTiresListFilters ?? { page: 1 }
  }

  @Selector()
  static lostTiresListColumnsVisibility(state: FiltersStateModel) {
    return state.lostTiresListColumnsVisibility
  }

  @Selector()
  static lostTiresListAdvancedMode(state: FiltersStateModel) {
    return state.lostTiresListAdvancedMode
  }

  @Selector()
  static adminUserListSortState(state: FiltersStateModel) {
    return state.adminUserListSortState
  }

  @Selector()
  static adminPromotionsListSortState(state: FiltersStateModel) {
    return state.adminPromotionsListSortState
  }

  @Selector()
  static trackerLocationId(state: FiltersStateModel) {
    return state.trackerLocationId
  }

  @Selector()
  static locationId(state: FiltersStateModel) {
    return state.locationId
  }

  @Selector()
  static communicationTypeFilter(state: FiltersStateModel) {
    return (
      state.communicationTypeFilter ?? {
        locationIds: []
      }
    )
  }

  @Selector()
  static weeklyCarCountsFilter(state: FiltersStateModel) {
    return (
      state.weeklyCarCountsFilter ?? {
        locationIds: []
      }
    )
  }

  @Selector()
  static weeklyTargetRankingSortState(state: FiltersStateModel) {
    return state.weeklyTargetRankingSortState
  }

  @Selector()
  static workOrdersSummaryFilter(state: FiltersStateModel) {
    return (
      state.workOrdersSummaryFilter ?? {
        locationIds: []
      }
    )
  }

  @Selector()
  static workOrdersSummarySortState(state: FiltersStateModel) {
    return state.workOrdersSummarySortState
  }

  @Selector()
  static workOrdersListFilters(state: FiltersStateModel) {
    return state.workOrdersListFilters ?? { page: 1 }
  }

  @Selector()
  static workOrdersListColumnsVisibility(state: FiltersStateModel) {
    return state.workOrdersListColumnsVisibility
  }

  @Selector()
  static workOrdersListAdvancedMode(state: FiltersStateModel) {
    return state.workOrdersListAdvancedMode
  }

  @Selector()
  static attendanceExpandedShopsId(state: FiltersStateModel) {
    return state.attendanceExpandedShopsId ?? []
  }

  @Selector()
  static attendanceSelectedWeekNumber(state: FiltersStateModel) {
    return state.attendanceSelectedWeekNumber
  }

  @Selector()
  static attendanceSelectedRegion(state: FiltersStateModel) {
    return state.attendanceSelectedRegion
  }

  @Selector()
  static attendanceSelectedDivision(state: FiltersStateModel) {
    return state.attendanceSelectedDivision
  }

  ngxsOnInit(ctx: StateContext<FiltersStateModel>) {
    const persistObj = localStorage.getItem(LOCAL_STORAGE_KEYS.FILTERS)
    if (!persistObj) {
      return
    }
    const filters: Partial<FiltersStateModel> = JSON.parse(persistObj)

    if (isEmpty(filters) || !isObject(filters)) {
      return
    }

    for (const key in filters) {
      ctx.patchState({ [key]: filters[key as FilterStateKeys] })
    }
  }

  @Action(SetFilters)
  setFilters(ctx: StateContext<FiltersStateModel>, { filters }: SetFilters) {
    if (isEmpty(filters)) {
      return
    }
    for (const key in filters) {
      ctx.patchState({ [key]: filters[key as FilterStateKeys] })
    }
    this.saveFiltersToLocalStorage(ctx.getState())
    this.updateQueryParams(ctx.getState())
  }

  @Action(SetAdvancedTableFilters)
  setAdvancedTableFilters(
    ctx: StateContext<FiltersStateModel>,
    { key, value }: SetAdvancedTableFilters
  ) {
    if (!value?.filters) {
      return
    }
    const filters: ITableFilter | undefined = { ...value.filters }
    if (isEmpty(filters)) {
      return
    }
    for (const key in filters) {
      filters[key] = omit(filters[key], ['enumItems'])
    }

    ctx.patchState({ [key]: { ...value, filters } })
    this.saveFiltersToLocalStorage(ctx.getState())
    this.updateQueryParams(ctx.getState())
  }

  @Action(SetFiltersFromUrlQueryParams)
  setFiltersFromUrlQueryParams(
    ctx: StateContext<FiltersStateModel>,
    { filtersToken }: SetFiltersFromUrlQueryParams
  ) {
    try {
      if (!filtersToken || filtersToken === '') {
        return
      }
      const filters = jwt_decode<FiltersStateModel>(filtersToken)
      if (!filters || isEmpty(filters)) {
        return
      }
      if (filters.appVersion !== AppVersion) {
        this.router.navigateByUrl(`/dashboard/${NewVersionIndicator}`)
        return
      }

      const validFilters = this.pickValidFilters(filters)
      ctx.patchState({ ...validFilters })
      this.saveFiltersToLocalStorage(ctx.getState())
      this.updateQueryParams(ctx.getState())
    } catch (error) {
      return
    }
  }

  @Action(SetUrlQueryParamsFromState)
  setUrlQueryParamsFromState(ctx: StateContext<FiltersStateModel>) {
    this.updateQueryParams(ctx.getState())
  }

  private updateQueryParams(state: FiltersStateModel) {
    const currentUrl = new URL(document.URL)
    let currentFilters = {}
    const filtersToken = Object.fromEntries(new URLSearchParams(currentUrl.search) as any)['filters']
    if (filtersToken && filtersToken !== '') {
      try {
        currentFilters = jwt_decode<FiltersStateModel>(filtersToken)
      } catch (error) {
        currentFilters = {}
      }
    }
    const currentPath = this.router.url.split('?')[0]
    const validFilters = this.pickValidFilters({ ...currentFilters, ...state })

    if (!validFilters) {
      this.location.replaceState(currentPath)
      return
    }

    this.location.replaceState(
      `${currentPath}?filters=${sign(
        { ...validFilters, appVersion: AppVersion },
        environment.jwtSecret
      )}`
    )
  }

  private pickValidFilters(filters: Partial<FiltersStateModel>) {
    const currentPath = this.router.url.split('?')[0]
    const pageQueryParams = PageQueryParamsMap[currentPath]
    if (!pageQueryParams) {
      return
    }
    return pickBy(filters, (value, key) => pageQueryParams.find((el) => el === key))
  }

  private static evaluateBoolean(value?: string | boolean) {
    return value !== undefined ? value === 'true' || value === true : undefined
  }

  private saveFiltersToLocalStorage(state: FiltersStateModel) {
    const persistObj = pick(state, PersistFilters)
    if (persistObj && !isEmpty(persistObj)) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.FILTERS, JSON.stringify(persistObj))
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.FILTERS)
    }
  }
}
