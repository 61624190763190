export class GetAttendance {
  static readonly type = '[Attendance] Get Attendance'
  constructor(public weekNumber: number) {}
}

export class ResyncAttendance {
  static readonly type = '[Attendance] Resync Attendance'
  constructor(public weekNumber: number) {}
}

export class EmployeeAttendanceDetails {
  static readonly type = '[Attendance] Employee Attendance Details'
  constructor(
    public employeeId: string,
    public locationId: string,
    public from: Date
  ) {}
}
