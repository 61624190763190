import { ITimePeriodWithWeekNumber } from '@lla-platform/core/core-data-access'
import {
  IAttendanceResponse,
  IEmplyeeAttendanceDetailReponse
} from '../../interfaces/attendance.interface'

export interface AttendanceStateModel {
  availableWeeks: ITimePeriodWithWeekNumber[]
  response: IAttendanceResponse
  employeeDetails: IEmplyeeAttendanceDetailReponse
}
