import {
  IArInvoiceRequest,
  IGpPackageListRequest,
  IInvoiceGrossProfitRequest,
  IPackageUnperformedRequest,
  IReceivablesSummaryByShopRequest
} from '@lla-platform/receivable/receivable-data-access'
import { IColumnsVisibility } from '../../interfaces/advanced-table.interface'
import { ITimePeriodRequest } from '../../interfaces/time-period.interface'
import {
  ICommunicationTypeRequest,
  ICommunicationsListInfo,
  ICustomerRequest,
  ICustomersListInfo,
  IDeferredTypeRequest
} from '@lla-platform/customers/customers-data-access'
import { Sort } from '@angular/material/sort'
import { ITiresRequest } from '@lla-platform/tires/tires-data-access'
import { IWeeklyCarCountsRequest } from '@lla-platform/car-counts/car-counts-data-access'
import { IWorkOrdersSummaryRequest } from 'work-orders-data-access'
import { INew_TableAction } from '../../interfaces/table-filter.interface'

export interface FiltersStateModel {
  isLoading: boolean
  locationId: string
  listPagesPath: { [key: string]: string }
  receivableListFilters: INew_TableAction
  receivableColumnsVisibility: IColumnsVisibility
  receivableListAdvancedMode: boolean
  receivableListReturnUrl: string
  shopReceivableSummaryFilter: IReceivablesSummaryByShopRequest
  arSummaryFilter: IArInvoiceRequest
  invoicesListFilters: INew_TableAction
  invoicesListColumnsVisibility: IColumnsVisibility
  invoicesListAdvancedMode: boolean
  invoicesListReturnUrl: string
  grossListFilters: INew_TableAction
  grossListColumnsVisibility: IColumnsVisibility
  grossListAdvancedMode: boolean
  grossListReturnUrl: string
  gpPackageListFilters: INew_TableAction
  gpPackageListColumnsVisibility: IColumnsVisibility
  gpPackageListAdvancedMode: boolean
  gpPackageListReturnUrl: string
  accountReceivableFilter: IReceivablesSummaryByShopRequest
  showLargeMenu: boolean
  showAppliedTableFilters: boolean
  customersSummaryFilter: ICustomerRequest
  deferredTypesSummaryFilter: IDeferredTypeRequest
  deferredTypesSortState: Sort
  grossProfitSummaryFilter: IInvoiceGrossProfitRequest
  grossProfitSortState: Sort
  gpPackageByLocationSummaryFilter: IGpPackageListRequest
  gpPackageByLocationSortState: Sort
  gpPackageByCategorySummaryFilter: IGpPackageListRequest
  gpPackageByCategorySortState: Sort
  customersListFilters: ICustomersListInfo
  customersListColumnsVisibility: IColumnsVisibility
  clientDetailReturnUrl: string
  communicationsListTableFilters: ICommunicationsListInfo
  communicationsListColumnsVisibility: IColumnsVisibility
  weeklyTargetExpandedShopsId: string[]
  weeklyTargetSelectedWeekNumber: number
  weeklyTargetSelectedRegion: string
  weeklyTargetSelectedDivision: string
  TargetRankingByLocationSelectedRegion: string
  TargetRankingByLocationSelectedDivision: string
  TargetRankingByRegionSelectedRegion: string
  TargetRankingByRegionSelectedDivision: string
  weeklyTargetRankingSortState: Sort
  arPaymentsSummaryFilter: IArInvoiceRequest
  packageUnperformedSummaryFilter: IPackageUnperformedRequest
  callsReportFilter: ITimePeriodRequest
  paymentsListFilters: INew_TableAction
  paymentsListColumnsVisibility: IColumnsVisibility
  paymentsListAdvancedMode: boolean
  adminShopTiresExpandedShopsId: string[]
  promotionsExpandedShopsId: string[]
  promotionsUsersExpandedIds: string[]
  tiresSummaryFilter: ITiresRequest
  tiresByLevelSummaryFilter: ITiresRequest
  tiresByStockableSummaryFilter: ITiresRequest
  tiresListFilters: INew_TableAction
  tiresListColumnsVisibility: IColumnsVisibility
  tiresListAdvancedMode: boolean
  tiresListReturnUrl: string
  adminUserListSortState: Sort
  adminPromotionsListSortState: Sort
  trackerLocationId: string
  communicationTypeFilter: ICommunicationTypeRequest
  weeklyCarCountsFilter: IWeeklyCarCountsRequest
  lostTiresListFilters: INew_TableAction
  lostTiresListColumnsVisibility: IColumnsVisibility
  lostTiresListAdvancedMode: boolean
  appVersion: string
  workOrdersSummaryFilter: IWorkOrdersSummaryRequest
  workOrdersSummarySortState: Sort
  workOrdersListFilters: INew_TableAction
  workOrdersListColumnsVisibility: IColumnsVisibility
  workOrdersListAdvancedMode: boolean
  attendanceExpandedShopsId: string[]
  attendanceSelectedWeekNumber: number
  attendanceSelectedRegion: string
  attendanceSelectedDivision: string
}

export type FilterStateKeys = keyof FiltersStateModel
