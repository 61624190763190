<div (click)="menuTrigger.openMenu()">
  <div #contentSlot>
    <ng-content></ng-content>
  </div>

  <div
    *ngIf="!contentSlot.children.length"
    class="w-full md:w-[244px] p-xs pl-sm rounded-sm bg-white md:hover:bg-ui-hover flex justify-between items-center cursor-pointer {{
      extraClasses
    }}"
  >
    <div class="flex-1 overflow-hidden pr-sm flex flex-col">
      <div class="text-2xs leading-2xs text-txt-secondary">
        {{ selectedPeriodType ? periodTypeToLabel[selectedPeriodType] : 'Select Period' }}
      </div>
      <div class="text-sm leading-sm truncate">{{ formattedPeriodTime }}</div>
    </div>

    <div class="flex items-center justify-center p-base bg-primary rounded-sm text-contrast">
      <svg-icon key="calendar-light" class="!text-base"></svg-icon>
    </div>
  </div>
</div>

<div
  [matMenuTriggerFor]="dateRangePickerMenu"
  #menuTrigger="matMenuTrigger"
  (menuOpened)="menuIsOpen = true"
  (menuClosed)="setSelectedDate(); menuIsOpen = false"
></div>

<mat-menu
  #dateRangePickerMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="lla-filter-menu-full"
>
  <div
    class="flex-1 flex flex-col overflow-auto w-full md:w-[442px]"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <lla-range-date-picker
      *ngIf="menuIsOpen"
      focusInput="start"
      [value]="selectedValue"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [hideAllPeriods]="hideAllPeriods"
      [hiddenPeriods]="hiddenPeriods"
      (valueChanged)="valueChanged.emit($event); menuTrigger.closeMenu()"
      (canceld)="menuTrigger.closeMenu()"
    ></lla-range-date-picker>
  </div>
</mat-menu>
