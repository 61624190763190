import { DayOfWeek, IShopInfo, ITimePeriod } from '@lla-platform/core/core-data-access'

export interface IAttendanceDay {
  dayOfWeek: DayOfWeek
  workingDay: Date
  workedMinutes: number
  workedMinutesFormated: string
  isValid: boolean
}

export interface IAttendanceItem {
  employeeId: string
  onSite: boolean
  name: string
  days?: IAttendanceDay[]
  totalMinutes: number
  total: string
  overTimeMinutes: number
  overTime: string
}

export interface IAttendanceInfo extends IShopInfo {
  employees: IAttendanceItem[]
}

export interface IAttendanceResponse {
  currentWeek: ITimePeriod
  locations: IAttendanceInfo[]
}

export interface IEmplyeeAttendanceItem {
  attendanceId?: string
  clockIn?: string
  clockOut?: string
  total?: string
  totalMinutes?: number
  note?: string
  errorMesage?: string
  isValid?: boolean
}

export interface IEmplyeeAttendanceDetailReponse {
  day: Date
  dayFormatted: string
  attendances: IEmplyeeAttendanceItem[]
  grandTotal: string
}
