<div
  class="flex items-center py-sm mt-sm md:mt-0 md:bg-white md:rounded-t-sm"
  [ngClass]="{
    'md:rounded-b-sm': !expanded,
    'cursor-pointer': fullClickable
  }"
  (click)="expandable && fullClickable ? toggleCollapse.emit() : undefined"
>
  <div class="flex-1 flex items-center">
    <div class="md:pl-base pr-base flex items-center justify-center text-txt-secondary">
      <svg-icon [key]="icon" class="!text-sm"></svg-icon>
    </div>
    <div class="pr-base flex-1 md:flex-none text-txt font-semibold text-base leading-base">
      {{ label }}
    </div>
    <div
      *ngIf="expandable"
      class="flex text-gray-400 hover:text-txt cursor-pointer"
      (click)="!fullClickable ? toggleCollapse.emit() : undefined"
    >
      <svg-icon [key]="expanded ? 'minus' : 'plus'" class="!text-xs"></svg-icon>
    </div>
  </div>

  <ng-content></ng-content>
</div>
