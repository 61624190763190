import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Chart } from 'chart.js'
import { saveAs } from 'file-saver'
import chartTrendline from 'chartjs-plugin-trendline'
import { IChartInfo } from '../../interfaces/chart.interface'
import { ISalesReportItem } from '@lla-platform/reports/reports-data-access'
import { ChartDataType } from '../../enums/chart-data-type.enum'

@UntilDestroy()
@Component({
  selector: 'lla-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() labels: string[] = []
  @Input() info: IChartInfo<ISalesReportItem>[] = []

  @ViewChild('lineCanvas') lineCanvas: ElementRef

  lineChart: Chart

  borderWidth = 1
  pointRadius = 2

  ngOnInit(): void {
    Chart.register(chartTrendline)
  }

  ngAfterViewInit(): void {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: this.labels,
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        interaction: {
          mode: 'index',
          intersect: false
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          },
          tooltip: {
            mode: 'index',
            callbacks: {
              label: (tooltipItem: any) => {
                const chartDataType = tooltipItem.dataset.chartDataType
                return `${tooltipItem.dataset.label}: ${
                  chartDataType !== ChartDataType.CarCount ? '$' : ''
                }${tooltipItem.formattedValue}`
              }
            }
          }
        },
        scales: {
          x: {
            display: true
          },
          y: {
            type: 'linear',
            display: 'auto',
            position: 'left',
            grid: {
              drawOnChartArea: false
            }
          },
          y1: {
            type: 'linear',
            display: 'auto',
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          },
          y2: {
            type: 'linear',
            display: 'auto',
            position: 'right',
            grid: {
              drawOnChartArea: false
            }
          }
        }
      }
    })
  }

  ngOnChanges(): void {
    this.updateChart()
  }

  ngOnDestroy(): void {
    Chart.unregister(chartTrendline)
    this.lineChart?.destroy()
  }

  updateChart() {
    if (!this.lineChart) {
      return
    }
    this.lineChart.data.labels = this.labels
    this.lineChart.data.datasets =
      this.info && this.info.length > 0
        ? this.info.map((el) => {
            let dataset: any = {
              label: `${el.type} | ${el.shopName}`,
              data: this.labels.map((l) => {
                const foundItem = el.data.find((d) => d.month === l)
                return foundItem
                  ? el.type === ChartDataType.AvgTicket
                    ? foundItem.avgTicket
                    : el.type === ChartDataType.CarCount
                      ? foundItem.carCount
                      : el.type === ChartDataType.Deferred
                        ? foundItem.deferred
                        : foundItem.sales
                  : null
              }),
              borderColor: el.color,
              yAxisID:
                el.type === ChartDataType.AvgTicket
                  ? 'y1'
                  : el.type === ChartDataType.CarCount
                    ? 'y2'
                    : 'y',
              pointBackgroundColor: el.color,
              pointBorderColor: el.color,
              backgroundColor: el.color,
              borderWidth: this.borderWidth,
              pointRadius: this.pointRadius,
              chartDataType: el.type
            }

            if (dataset.data.filter((x: any) => x !== null).length > 1) {
              dataset = {
                ...dataset,
                trendlineLinear: {
                  colorMin: el.color,
                  colorMax: el.color,
                  lineStyle: 'dotted',
                  width: 2,
                  projection: false
                }
              }
            }
            return dataset
          })
        : []

    this.lineChart.update()
  }

  public printImage() {
    if (this.lineChart?.options?.plugins?.legend) {
      this.lineChart.options.plugins.legend.display = true
      this.lineChart.update()
      const image = this.lineChart.toBase64Image()
      saveAs(image, 'lla-chart.png')
      this.lineChart.options.plugins.legend.display = false
      this.lineChart.update()
    } else {
      const image = this.lineChart.toBase64Image()
      saveAs(image, 'lla-chart.png')
    }
  }
}
