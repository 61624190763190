<div
  class="min-h-[140px] w-full px-2xl pt-2xl md:pt-0 flex flex-col md:flex-row justify-between items-center bg-white rounded-sm {{
    containerClass
  }}"
  *ngIf="info"
>
  <div
    class="flex-1 flex flex-col items-center text-center md:text-left md:items-start mb-2xl md:mb-0 text-txt text-sm leading-sm"
  >
    <div #titleSlot>
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <span *ngIf="!titleSlot.children.length">{{ info.title }}</span>
    <span *ngIf="info.subTitle" class="block mt-sm">
      {{ info.subTitle }}
    </span>
  </div>
  <div class="ml-2xl flex-1 xl:flex-[2] flex justify-end">
    <img class="no-report-image" [src]="info.imagePath" alt="No Report Image" />
  </div>
</div>
